.navbar {
  background-color: rgba(243, 244, 246, 1);
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.nav-link {
  margin: 0px 10px;
  text-decoration: none;
  color: #272343;
  font-size: large;
  font-weight: 900;
  font-style: italic;
  border-bottom: 1px solid rgba(39, 35, 67, 68%);
}

.make-active {
  color: rgb(255, 75, 43);
  border-bottom: 1px solid rgb(255, 75, 43);
}
